.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.square1 {
    font-size: 3em;
}

.btn-circle.btn-xl {
    border-radius: 60px;
    font-size: 18px;
    text-align: center;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cabecera {
    background-color: #d9edff;  
    width: 100%;
    height: 65px;
    left: 0;
    top: 0;
    position: fixed;
    z-index:1;
}



.texto {
    font-family: "Open Sans", sans-serif;
    color: #3b4754;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.login {
    text-align: center;
    border-style: groove;
    border-width: 1px;
    width: 600px;
    margin-top: 80px;
}

.login2 {
    text-align: center;
    border-style: groove;
    border-width: 1px;
    width: 600px;
    margin-top: 80px;
}

.input {
    width: 90%;
    padding: .5em 1em;
}

@media (max-width:600px) {
    .login, .login2, .edit {
        width: 100%;
    }
}

.boton {
    font-size: 26px;
    width: 200px;
    height: auto;
    border-radius: 10px;
}

.boton2 {
    width: 230px;
    height: 60px;
    border-radius: 10px;
}

.click{
    cursor:pointer;
}

.edit {
    text-align: center;
    top: 50%;
    width: 400px;
}

.usericon {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: #007bff;
}

.usericon2 {
    margin: 0;
    padding: 0;
    font-size: 25px;
    color: #007bff;
}

.flecha {
    margin: 0;
    padding: 0;
    font-size: 30px;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
}

td {
    word-wrap: break-word;
    max-width: 5px;
    white-space: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

tr {
    white-space: nowrap;
}

.botonplus{
    height: 40px;
    width: 40px;
}

.centrado{
    text-align:center;
}

.msb {
    width: 245px;
    position: fixed;
    left: 0;

    white-space: nowrap;
    /*height: 100%;*/
    z-index: 1;
    border-right: 1px solid #ddd;
    margin-top: 65px;

    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(255,255,255); /* Fallback color */
}

.contenedor {
    margin-left: 250px;
    margin-top: 75px; /* Relleno superior igual a la altura de la cabecera*/
}

.contenedor2 {
    margin-left: 245px;
    margin-top: 75px; /* Relleno superior igual a la altura de la cabecera*/
}

.contenedorcentrado {
    margin-top: 75px; /* Relleno superior igual a la altura de la cabecera*/
}

.pregunta {
    border-style: solid;
    border-width: 1px;
}

.preguntabien {
    border-style: solid;
    border-width: 2px;
    border-color: rgba(0, 255, 0, 0.3);
    background-color: rgba(0, 255, 0, 0.1);
}

.preguntamal {
    border-style: solid;
    border-width: 2px;
    border-color: rgba(255, 0, 0, 0.3);
    background-color: rgba(255, 0, 0, 0.1);
}

.muestrapregunta {
    display: inline-block;
    width: 60%;
}

.seleccionadorpregunta {
    display: inline-block;
    width: 30%;
    position: absolute;
    border-style: solid;
    border-width: 2px;
    border-color: #0275d8;
    margin-left: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.ranking {
    width: 40%;
    margin-right: 20px;
    display: inline-block;
}

@media (max-width:1200px) {
    .contenedor {
        margin-left: 5px;
    }

    .contenedor2{
        margin-left: 0px;
    }

    .msb {
        width: 100%;
        background-color: rgba(0,0,0,0.4); /* Fallback color */
    }

    .ranking{
        width:100%;
    }
}

@media (max-width:1492px) {
    .muestrapregunta {
        width: 100%;
    }

    .seleccionadorpregunta {
        width: 100%;
        position: relative;
        margin-top: 10px;
        margin-left: 0px;
    }
}
.celdaUsuario{
    white-space:normal;
}

.contenedortemas {
    margin-top: 100px;
}

.contenedortemas2 {
    text-align: center;
    display: inline-block;
}

.contenedorstats {
    width: 70%;
    margin-top: 50px;
}

@media (max-width:1200px) {
    .contenedorstats {
        width: 100%;
    }
}

.contenedorstats2 {
    text-align: center;
    display: inline-block;
    background-color: #d9edff;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    width: 400px;
}

.progress {
    height: 25px;
}

.progress-bar {
    height: 100%;
    display: flex;
    align-items: center;
    transition: width 0.25s;
    border-radius: 5px;
}

.progress-bar-text {
    margin-left: 10px;
    font-weight: bold;

}

.botonpregunta {
    width: 45px;  
    margin-bottom: 8px;
}

.botonpregunta2 {
    width: 45px;
    background: linear-gradient(0deg, #0275d8 15%, white 15% 90%,white 90%);
    margin-bottom: 8px;
}

.botonmenu {
    margin: 0 auto;
    cursor: pointer;
    height: 60px;
}

.hijoboton {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.tablapreguntas {
    margin-left: 8px;
    margin-right: 8px;
}

.tablausuarios {
    margin-left: 8px;
    margin-right: 8px;
}

.filatest:hover {
    background-color: #d9edff;
    cursor: pointer;
}

.filatest {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
}

.columnaid{
    width: 70px;
}

.navbar-toggler > .close {
    display: inline;
}

.navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
    display: none;
}

.navbar-toggler.collapsed > .close {
    display: none;
}

.botoncolapse{
    height: 40px;
    width: 58px;
}

.vertical-center2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.texto2 {
    position: absolute;
    content: "";
    bottom: -15px;
    height: 40px;
    left: 30px;
    width: 100px;
}

.texto3 {
    position: absolute;
    content: "";
    bottom: -15px;
    height: 40px;
    left: 50px;
    width: 100px;
}

.link-like-button {
    background: none;
    color: blue; 
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
}

.link-like-button:hover,
.link-like-button:focus {
    text-decoration: underline;
    color: #0645ad; 
    outline: none; 
}
